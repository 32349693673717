






import { Component, Vue } from 'vue-property-decorator'
import HeartbeatList from '@/components/HeartbeatList.vue'

@Component({
  components: {
    HeartbeatList
  }
})
export default class Heartbeats extends Vue {}
