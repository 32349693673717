






import { Component, Vue } from 'vue-property-decorator'
import BlackoutList from '@/components/BlackoutList.vue'

@Component({
  components: {
    BlackoutList
  }
})
export default class Blackouts extends Vue {}
